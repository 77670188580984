<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>售后管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- 卡片视图区域 -->
      <el-row type="flex">
        <el-row>
          <el-col style="display: flex;">
            <el-input v-model="pages.orderId" class="search-input" placeholder="订单号" clearable />
            <el-input v-model="pages.ttOrderId" v-if="hasTaoteUI" class="search-input" placeholder="淘特订单号" clearable />
            <el-input v-model="pages.userName" class="search-input" placeholder="买家姓名" clearable />
            <el-input v-model="pages.userPhone" class="search-input" placeholder="买家电话" clearable />
            <el-input v-model="pages.logisticsOrderNumber" class="search-input" placeholder="发货物流单号" clearable />
            <el-input v-model="pages.refundLogisticsOrderNumber" class="search-input" placeholder="退货物流单号" clearable />
            <el-select v-model="pages.refundMessage" placeholder="售后原因" class="search-select" >
              <el-option v-for="item in refundMessageList" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-col>
          <el-col style="display: flex;margin: 10px 0;">
            <el-input v-model="pages.goodsTitle" class="search-input" placeholder="商品名称" clearable />
            
            <!-- <el-select v-model="pages.status" placeholder="订单状态" class="search-select" >
              <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select> -->
            <el-date-picker class="search-date" v-model="pages.beginDate" type="date" placeholder="售后开始时间" value-format="yyyy-MM-dd"></el-date-picker>
            <span style="line-height: 38px;margin: 0 5px;">-</span>
            <el-date-picker class="search-date" v-model="pages.endDate" type="date" placeholder="售后结束时间" value-format="yyyy-MM-dd"></el-date-picker>

            <el-date-picker style="margin-left: 5px;" class="search-date" v-model="pages.refundBeginDate" type="date" placeholder="退款开始时间" value-format="yyyy-MM-dd"></el-date-picker>
            <span style="line-height: 38px;margin: 0 5px;">-</span>
            <el-date-picker class="search-date" v-model="pages.refundEndDate" type="date" placeholder="退款结束时间" value-format="yyyy-MM-dd"></el-date-picker>
            <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
          </el-col>
          <div>
          <div style="display: flex; align-items: center;width: 100%;font-size: 12px;min-width: 700px;">
            <div>订单状态：</div>
           <div class="status-item" :class="{active: pages.status == item.value}" v-for="(item, index) in statusList" :key="index" @click="changeStatus(item.value)">{{item.key}}</div>
           </div>
          </div>
        </el-row>

        <el-col v-if="!isGuest" style="display: flex; align-items:flex-end;margin-left: 10px;justify-content:flex-end;">
          <el-button size="mini" type="warning" @click="exportOrder">导出Excel</el-button>
        </el-col>
      </el-row>
      </el-row>
      <el-table :data="orderList" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="支付宝交易号：">
                <span>{{ props.row.alipayOrderId}}</span>
              </el-form-item>
              <el-form-item label="买家姓名：">
                <secrectText :text="props.row.userName"/>
              </el-form-item>
              <el-form-item label="买家电话：">
                <secrectText :text="props.row.userPhone"/>
              </el-form-item>
              <el-form-item label="买家收货地址：">
                <secrectText :text="props.row.addressName"/>
              </el-form-item>
              <el-form-item label="发货物流公司：">
                <span>{{ props.row.logisticsBusiness}}</span>
              </el-form-item>
              <el-form-item label="发货物流单号：">
                <span>{{ props.row.logisticsOrderNumber}}</span>
              </el-form-item>
              <el-form-item label="用户补充描述：">
                <span>{{ props.row.remarkMessage}}</span>
              </el-form-item>
              <el-form-item label="照片凭证：">
                <div v-if="!!props.row.refundPics">
                  <el-image :src="item" v-for="(item,index) in props.row.refundPics.split(',')" :key="index" :preview-src-list="[item]"
                 style="width: 100px; height: 100px; border: none" />
                </div>
              </el-form-item>
              <el-form-item label="退货物流公司：">
                <span>{{ props.row.refundLogisticsBusiness}}</span>
              </el-form-item>
              <el-form-item label="退货物流单号：">
                <span>{{ props.row.refundLogisticsNumber}}</span>
              </el-form-item>
              <el-form-item label="拒绝退款理由：">
                <span>{{ props.row.refuseRefundMessage}}</span>
              </el-form-item>
              <el-form-item label="退款人姓名：">
                <span>{{ props.row.doRefundTaskBy || '--'}}</span>
              </el-form-item>
              <el-form-item label="退款时间：">
                <span>{{ props.row.doRefundTime || '--'}}</span>
              </el-form-item>
              <el-form-item label="备注：">
                <span>{{ props.row.refundGoodsMessage}}</span>
              </el-form-item>
              <el-form-item>
                <el-button v-if="props.row.status == 6666" @click="showUpdateRefundLog(props.row.orderId)"  size="mini" type="primary">填写退货信息</el-button>
                <el-button @click="showUpdateOrder(props.row.orderId, props.row.refundGoodsMessage)" size="mini" type="primary">备注</el-button>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="订单ID" prop="orderId" />
        <el-table-column v-if="hasTaoteUI" label="淘特订单ID" prop="ttOrderId" />
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <img :src="transferMainPic(scope.row.goodsImg)[0]"
                 style="width: 100px; height: 100px; border: none" />
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsTitle" />
        <el-table-column label="实付款(元)" prop="needToPay"/>
        <el-table-column label="实收" prop="truePay" />
        <el-table-column label="下单时间" prop="createTime"/>
        <el-table-column label="订单状态" prop="statusName"/>
        <el-table-column label="售后原因" prop="remark"/>
        <el-table-column label="售后申请时间" prop="refundTime"/>
        <el-table-column label="处理结果" prop="refundStatus"/>
        <!-- 操作 -->
        <el-table-column label="操作" v-if="!isGuest">
          <template slot-scope="props">
            <div v-if="props.row.status == 2222">
              <el-button @click="handleOrder(3, props.row.orderId)" type="primary">退款</el-button>
            </div>
            <div v-if="props.row.status == 6666 || props.row.status == 1111">
              <el-button @click="handleOrder(3, props.row.orderId)" type="primary">退款</el-button>
              <el-button @click="handleOrder(5, props.row.orderId)" type="danger">拒绝退款</el-button>
            </div>
            <div v-else-if="props.row.status == 5555">
              <el-button @click="handleOrder(1, props.row.orderId)" type="primary">同意退款</el-button>
              <el-button @click="handleOrder(2, props.row.orderId)" type="danger">拒绝退款</el-button>
            </div>
            <div v-else-if="props.row.status == 4444">
              <div v-if="props.row.remark.includes('退货退款')">
                <el-button @click="handleOrder(0, props.row.orderId)" type="primary">同意退货</el-button>
                <el-button @click="handleOrder(4, props.row.orderId)" type="danger">拒绝退货</el-button>
              </div>
              <div v-else>
                <el-button @click="handleOrder(1, props.row.orderId)" type="primary">同意退款</el-button>
                <el-button @click="handleOrder(2, props.row.orderId)" type="danger">拒绝退款</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="pages.currentPage"
                     :page-sizes="[3, 5, 10, 15]"
                     :page-size="pages.pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="选择退款地址" :visible.sync="dialogVisible" width="50%" >
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-row >
          <el-select v-model="address" style="width: 100%">
            <el-option v-for="(item,idx) in refundAddressList" :key="idx" :label="`${item.businessName}-${item.fullname}`" :value="item.id"></el-option>
          </el-select>
        </el-row>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改退货物流信息" :visible.sync="updateRefundLogVisible" width="50%" >
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-row >
          <el-select v-model="businessIndex" style="width: 100%">
            <el-option v-for="(item,idx) in businessList" :key="idx" :label="`${item.name}`" :value="idx"></el-option>
          </el-select>
        </el-row>
        <el-row style="margin-top: 10px;">
          <el-input v-model="refundLogisticsNumber" placeholder="输入物流单号" clearable />
        </el-row>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onUpdateRefundLogSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="拒绝退款" :visible.sync="refuseDialogVisible" width="50%" >
      <!-- 主要内容 -->
      <el-form label-width="100px">
        <el-row >
          <el-input v-model="refuseRefundMessage" placeholder="请输入拒绝退款/退货理由" clearable />
        </el-row>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onRefuseSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="备注" :visible.sync="updateOrderVisible" width="50%" >
      <!-- 主要内容 -->
      <el-form label-width="100px">
        
        <el-row style="margin-top: 10px;">
          <el-input type="textarea" v-model="refundGoodsMessage" placeholder="请输入备注信息" clearable />
        </el-row>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onUpdateOrderSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
import qs from 'qs'
import secrectText from '../secrectText.vue'
export default {
  components: {
    secrectText
  },
  data () {
    return {
      isGuest: false,
      pages: {
        currentPage: 1,
        pageSize: 10,
        ttOrderId: '',
        orderId: '',
        status: null,
        beginDate: '',
        endDate: '',
        refundBeginDate: '',
        refundEndDate: '',
        refundMessage: '',
        userPhone: '',
        goodsTitle: '',
        userName: '',
        logisticsOrderNumber: '',
        refundLogisticsOrderNumber: '',
      },
      dialogVisible: false,
      refuseDialogVisible: false,
      updateRefundLogVisible: false,
      // 总条数
      total: 0,

      // 订单列表
      orderList: [],
      statusList: [
        {key: '全部', value: null},
        {key: '申请退款(已发货)', value: '4444'},
        {key: '申请退款(未发货)', value: '5555'},
        {key: '等待买家退货', value: '6666'},
        {key: '等待商家收货', value: '1111'},
        {key: '商家收到退货', value: '2222'},
        {key: '已退款', value: '3333'},
      ],  
      refundMessageList:[
        {key: '全部', value: null},
        {key: '质量问题（退货退款）', value: '质量问题（退货退款）'},
        {key: '商家发错货（退货退款）', value: '商家发错货（退货退款）'},
        {key: '系统签收实物未收到', value: '系统签收实物未收到'},
        {key: '物流时间长未更新', value: '物流时间长未更新'},
        {key: '其他（退货退款）', value: '其他（退货退款）'},
      ],
      address: '',
      businessIndex: '',
      refundAddressList: [],
      businessList: [],
      refundId: '',
      refuseRefundMessage: '',
      refuseRefundType: '',
      refundLogisticsNumber: '',
      refundGoodsMessage: '',
      updateOrderVisible: false,
      hasTaoteUI: false,
    }
  },
  created () {
    this.hasTaoteUI = window.hasTaoteUI();
    // 订单列表
    this.GetOrders()
    this.$api.QueryRefundAddressList({}).then((res) => {
        this.refundAddressList = res.data
        this.address = res.data[0].id;
    })
    this.$api.QueryBusinessList({}).then((res) => {
        this.businessList = res.data
        this.business = res.data[0].id;
    })
    this.isGuest = isGuest();
  },
  methods: {
    changeStatus(status){
      this.pages.status = status;
      this.search();
    },
    search(){
      let {beginDate, endDate} = this.pages;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      this.pages.currentPage = 1;
      this.GetOrders();
    },
    // 订单列表
    GetOrders () {
      this.$api.GetRefundOrders(this.pages).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求订单列表失败')
        }
        //  else {
        //   this.$message.success('请求订单列表成功')
        // }
        console.log(res)
        this.orderList = res.data.records
        this.total = res.data.total
      })
    },

    handleOrder(type, id){
      var types = [
        '确定同意退货吗？',
        '确定同意退款吗？',
        '确定拒绝退款吗？',
        '确定退款吗？',
        '确定拒绝退货吗？',
        '确定拒绝退款吗？',
      ]
      var params = {orderId: id};
      
      this.$confirm(types[type], '订单操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            if(type == 0){
              this.refundId = id;
              this.dialogVisible = true;
            }else if([2,4,5].includes(type)){
              this.refundId = id;
              this.refuseRefundType = type;
              this.refuseDialogVisible = true;
            }else{
              this.refund(type, params)
            }
          }
        }
      })
    },
    showUpdateRefundLog(orderId){
      this.refundId = orderId;
      this.updateRefundLogVisible = true;
    },
    exportOrder(){
      let p = {...this.pages};
      delete p.currentPage;
      delete p.pageSize;
      Object.keys(p).map(o => {
        if(!p[o]){
          delete p[o]
        }
      })
      let {beginDate, endDate} = p;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      console.log('/api/OrderAdminModule/adolf-order/OrderTestExcelForAfterSale'+ "?" + qs.stringify(p))
      window.open('/api/OrderAdminModule/adolf-order/OrderTestExcelForAfterSale'+ "?" + qs.stringify(p))
      return;
    },
    onSubmit(){
      this.refund(0, {
        orderId: this.refundId,
        refundAddress: this.address
      })
      this.dialogVisible = false;
    },
    onRefuseSubmit(){
      this.refund(this.refuseRefundType, {
        orderId: this.refundId,
        refuseRefundMessage: this.refuseRefundMessage
      })
      this.refuseDialogVisible = false;
    },

    onUpdateRefundLogSubmit(){
      this.$api.AddRefundMessage({
        orderId: this.refundId, 
        refundLogisticsBusiness: this.businessList[this.businessIndex].name,
        refundLogisticsNumber: this.refundLogisticsNumber
      }).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('操作失败:' + (res.message || ''))
          }
          this.$message.success('修改物流信息成功')
          this.GetOrders();
          this.updateRefundLogVisible = false;
      })
    },

    refund(type, params){
      this.$api.refundOrder(type, params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('操作失败:' + (res.message || ''))
          }
          this.GetOrders();
      })
    },

    // 选中页面值
    handleSizeChange (newSize) {
      console.log(newSize)
      this.pages.pageSize = newSize
      this.GetOrders()
    },
    //当页面变动的时候
    handleCurrentChange (NewPage) {
      console.log(NewPage)
      this.pages.currentPage = NewPage
      this.GetOrders()
    },
    
    showUpdateOrder(orderId, msg){
      this.currentId = orderId;
      this.refundGoodsMessage = msg;
      this.updateOrderVisible = true;
    },

    onUpdateOrderSubmit(){
      this.$api.AddRemark({
        orderId: this.currentId, 
        refundGoodsMessage: this.refundGoodsMessage,
      }).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('操作失败:' + (res.message || ''))
          }
          this.$message.success('修改成功')
          this.GetOrders();
          this.updateOrderVisible = false;
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:140px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
  .el-input--suffix .el-input__inner{
    padding-rigth: 10px;
  }
}
.status-item{
  padding: 4px 6px;
  border: 1px solid #ddd;
  margin-left: 5px;
  border-radius: 5px;
  &.active{
    background: #1b9aee;
    border-color: #1b9aee;
    color: #fff;
  }
}
</style>
